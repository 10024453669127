import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Error from "../components/Error";
import Body from "../components/Body";
import Profile from "../components/ProfileClassComponent";
import Contact from "../components/Contact";
import Products from "../components/Products";
import BananaPowderProduct from "../components/products/BananaPowderProduct";
import EggsProduct from "../components/products/EggsProduct";
import FrozenChickenProduct from "../components/products/FrozenChickenProduct";
import VermiCompostProduct from "../components/products/VermiCompostProduct";
import RestaurantMenu from "../components/RestaurantMenu";
import AppLayout from "../components/AppLayout";
import Shimmer from "../components/Shimmer";
import InstaMart from "../components/InstaMart";

const About = lazy(() => import("../components/About"));

const useRoute = () => {
  const appRoute = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      errorElement: <Error />,
      children: [
        {
          path: "/",
          element: <Body />,
        },
        {
          path: "/about",
          element: <About />,
          children: [
            {
              path: "profile",
              element: <Profile />,
            },
          ],
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/products",
          element: <Products />,
        },
        {
          path: "/products/bananaPowder",
          element: <BananaPowderProduct />,
        },
        {
          path: "/products/eggs",
          element: <EggsProduct />,
        },
        {
          path: "/products/FrozenChicken",
          element: <FrozenChickenProduct />,
        },
        {
          path: "/products/vermiCompost",
          element: <VermiCompostProduct />,
        },
        {
          path: "/restaurant/:resId",
          element: <RestaurantMenu />,
        },
        {
          path: "/certificates",
          element: <InstaMart />,
        },
      ],
    },
  ]);
  return appRoute;
};

export default useRoute;
